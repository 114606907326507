<template>
    <component :is="workData === undefined ? 'div' : 'b-card'">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="workData === undefined">
            <h4 class="alert-heading">Error fetching work data</h4>
            <div class="alert-body">
                No work found with this work id. Check
                <b-link class="alert-link" :to="{ name: 'works-list' }">
                    Work List
                </b-link>
                for other works.
            </div>
        </b-alert>
        <work-edit-tab-information v-if="workData" :work-data="workData"  @refetch-data="refetchData" class="mt-2 pt-75" />
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import workStoreModule from "@/views/admin/work/workStoreModule";
// import WorkEditTabAccount from "./WorkEditTabAccount.vue";
import WorkEditTabInformation from "./WorkEditTabInformation.vue";


export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        // WorkEditTabAccount,
        WorkEditTabInformation,
    },
    setup() {
        const workData = ref(null);

        const USER_APP_STORE_MODULE_NAME = "app-work";

        const work = {
            id: 0,
            title: 'WFH',
            remark: '',
            work_date: '',
            time_from: '10:00',
            time_to: '18:00',
            contents: [{
                id: 0,
                time_from: '',
                time_to: '',
                work_remark: ''
            }]
        }
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            store.registerModule(USER_APP_STORE_MODULE_NAME, workStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const refetchData = () => {
            if (router.currentRoute.name == 'works-create') {
                workData.value = work
            } else {
                store
                    .dispatch("app-work/fetchWork", { id: router.currentRoute.params.id })
                    .then((response) => {
                        workData.value = response.data.work;
                    })
                    .catch((error) => {
                        if (error.response.status === 404) {
                            workData.value = undefined;
                        }
                    });
            }

        };

        refetchData()

        return {
            workData,
            refetchData,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

    #work_form .ql-editor {
    height: 150px;
    }



    #work_form .date_from > label::before {
        content: "From";
        position:absolute;
        right:5%;
    }

    #work_form .date_to > label::before {
        content: "To";
        position:absolute;
        right:5%;
    }

</style>
